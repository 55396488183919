import {
  BsFileEarmarkFill,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeGif,
  BsFiletypeJpg,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypeSvg,
  BsFiletypeXls,
  BsFiletypeXlsx
} from 'react-icons/bs'
import moment from 'moment'

export * from './i18n'

export const roles = ['pharmacist', 'technician', 'bot', 'user', 'nurse']

export const professions = ['doctor', 'dentist', 'pharmacist', 'optometrist', 'nurse', 'podiatrist', 'midwife', 'veterinarian']

export const bannerUrls = [
  { url: '/banners/access-pharma.png', name: 'Accès pharma' },
  { url: '/banners/brunet.png', name: 'Brunet' },
  { url: '/banners/familiprix.png', name: 'Familiprix' },
  { url: '/banners/groupe-horizon-santé.png', name: 'Group Horizon Sante' },
  { url: '/banners/PJC.png', name: 'JeanCoutu' },
  { url: '/banners/pharmaprix.png', name: 'Pharmaprix' },
  { url: '/banners/proxim.png', name: 'Proxim' },
  { url: '/banners/uniprix.png', name: 'Uniprix' },
  { url: '/banners/independant.png', name: 'Indépendant' },
  { url: '/banners/tous-les-autres.png', name: 'Autre' }
]

export const convertFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const provinces = [
  { value: 'alberta', label: 'Alberta' },
  { value: 'britishColumbia', label: 'British Columbia' },
  { value: 'manitoba', label: 'Manitoba' },
  { value: 'newBrunswick', label: 'New Brunswick' },
  { value: 'newfoundlandAndLabrador', label: 'Newfoundland and Labrador' },
  { value: 'northwestTerritories', label: 'Northwest Territories' },
  { value: 'novaScotia', label: 'Nova Scotia' },
  { value: 'nunavut', label: 'Nunavut' },
  { value: 'ontario', label: 'Ontario' },
  { value: 'princeEdwardIsland', label: 'Prince Edward Island' },
  { value: 'quebec', label: 'Quebec' },
  { value: 'saskatchewan', label: 'Saskatchewan' },
  { value: 'yukon', label: 'Yukon' }
]

export const splitCamelCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const documentCategories = ['picture', 'medicalForm', 'medicalInformation', 'insuranceProof', 'prescription', 'task']

export const fileTypeIcon = (extension) => {
  switch (extension) {
    case 'pdf':
      return BsFiletypePdf
    case 'png':
      return BsFiletypePng
    case 'svg':
      return BsFiletypeSvg
    case 'jpg':
      return BsFiletypeJpg
    case 'xls':
      return BsFiletypeXls
    case 'xlsx':
      return BsFiletypeXlsx
    case 'csv':
      return BsFiletypeCsv
    case 'docx':
      return BsFiletypeDocx
    case 'doc':
      return BsFiletypeDoc
    case 'gif':
      return BsFiletypeGif
    default:
      return BsFileEarmarkFill
  }
}

export const generateFromWeeks = ({ startAt, stopAt, loopNumber, repeatValue }: { startAt: Date; stopAt: Date; loopNumber: number; repeatValue: string }) => {
  const weeks = []
  const currentDate = moment(startAt)

  if (loopNumber >= 1) {
    Array.from({ length: loopNumber }).forEach(() => {
      if (currentDate.format('dddd') === moment(startAt).format('dddd')) {
        weeks.push(currentDate.clone())
      }
      currentDate.add(Number(repeatValue), 'weeks') // Move to next week
    })
    return weeks
  }

  while (currentDate.isSameOrBefore(stopAt)) {
    if (currentDate.format('dddd') === moment(startAt).format('dddd')) {
      weeks.push(currentDate.clone().toDate())
    }
    currentDate.add(Number(repeatValue), 'weeks') // Move to next week
  }

  return weeks
}

export const generateFromMonth = ({ startAt, stopAt, loopNumber, repeatValue }: { startAt: Date; stopAt: Date; loopNumber: number; repeatValue: string }) => {
  const dayOfMonth = Number(moment(startAt).date())
  const endDate = stopAt ? moment(stopAt) : null

  const dates = []
  let currentDate = moment(startAt) // Start at the beginning of the current month

  if (loopNumber >= 1) {
    Array.from({ length: loopNumber }).forEach(() => {
      dates.push(currentDate.toDate())
      currentDate = currentDate.add(Number(repeatValue), 'month')
    })
    return dates
  }

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.toDate())
    currentDate = currentDate.add(Number(repeatValue), 'month')
  }

  return dates
}

export const generateFromDay = ({ startAt, stopAt, loopNumber, repeatValue }: { startAt: Date; stopAt: Date; loopNumber: number; repeatValue: string }) => {
  const dates = []
  let currentDate = moment(startAt)
  const endDate = stopAt ? moment(stopAt) : null

  if (loopNumber >= 1) {
    Array.from({ length: loopNumber }).forEach(() => {
      dates.push(currentDate.clone().toDate())
      currentDate = currentDate.add(Number(repeatValue), 'days')
    })
    return dates
  }

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.clone().toDate())
    currentDate = currentDate.add(Number(repeatValue), 'days')
  }

  return dates
}
